import { createGlobalStyle } from "styled-components"
import get from "lodash/get"
import LinesDark from "images/lines-dark.svg"
import LinesLight from "images/lines-light.svg"

export const SCREEN_SIZES = {
  largeDesktop: 1600,
  mediumDesktop: 1400,
  desktop: 1200,
  smallDesktop: 1024,
  tabletLandscape: 900,
  tabletPortrait: 600,
  mobileLarge: 425,
  mobileMedium: 375,
}

export const sizes = {
  mobileMedium: `(min-width: ${SCREEN_SIZES.mobileMedium}px)`,
  mobileLarge: `(min-width: ${SCREEN_SIZES.mobileLarge}px)`,
  tabletPortrait: `(min-width: ${SCREEN_SIZES.tabletPortrait}px)`,
  tabletLandscape: `(min-width: ${SCREEN_SIZES.tabletLandscape}px)`,
  smallDesktop: `(min-width: ${SCREEN_SIZES.smallDesktop}px)`,
  desktop: `(min-width: ${SCREEN_SIZES.desktop}px)`,
  mediumDesktop: `(min-width: ${SCREEN_SIZES.mediumDesktop}px)`,
  largeDesktop: `(min-width: ${SCREEN_SIZES.largeDesktop}px)`,
}

export const th = path => props => get(props.theme, path)

// All the code that controls vertical stacking is in one place. The idea being
// that you could slip something in between later if need be, which you couldn't
// if you did 1, 2, 3, etc, because z-index doesn't support decimals.
export const zIndexes = {
  notification: 9000,
  overlay: 8000,
  modal: 7000,
  navigation: 6000,
  dropdown: 5000,
}

const sharedTheme = {
  font: {
    text: "'Montserrat', sans-serif",
    headings: "century-old-style-std, serif",
  },
  color: {
    blue: "#5FA0FF",
    pink: "#FFA0FF",
    green: "#7DD300",
    yellow: "#FFD300",
    nude: "#F6E8D7",
    white: "#FFFFFF",
  },
  backgroundColor: "#FFFFFF",
  textColor: "#141414",
}

export const lightTheme = {
  ...sharedTheme,
  backgroundColor: "#FFFFFF",
  textColor: "#141414",
  lines: `url("${LinesLight}")`,
  overlayColor: "#F6E8D7",
  modeToggleButtonColor: "#F6E8D7",
  modeToggleButtonBorderColor: "#F6E8D7",
}

export const darkTheme = {
  ...sharedTheme,
  backgroundColor: "#141414",
  textColor: "#F6E8D7",
  lines: `url("${LinesDark}")`,
  overlayColor: "#141414",
  modeToggleButtonColor: "transparent",
  modeToggleButtonBorderColor: "#F5E5D1",
}

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: ${th("font.text")};
    font-size: 16px;
  }

  html {
    background-color: ${th("backgroundColor")};
    color: ${th("textColor")};
    background-image: ${th("lines")};
    background-position: center center;
    transition: background-color 400ms, color 400ms;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${th("font.headings")};
    font-weight: normal;
  }

  h1, h2 {
    font-size: 30px;
    line-height: 32px;

    @media ${sizes.tabletPortrait} {
      font-size: 40px;
      line-height: 44px;
    }

    @media ${sizes.smallDesktop} {
      font-size: 60px;
      line-height: 64px;
    }
  }

  p {
    line-height: 26px;
  }

  a {
    cursor: pointer;
    color: ${th("color.blue")};
    text-decoration: none;
    transition: color 200ms;

    :hover {
      color: ${th("color.pink")};
    }
  }
`
