import { SET_IS_DARK_THEME, SET_IS_LEAVING_PAGE } from "./types"

export const setIsDarkTheme = isDarkTheme => ({
  type: SET_IS_DARK_THEME,
  payload: isDarkTheme,
})

export const setIsLeavingPage = isLeavingPage => ({
  type: SET_IS_LEAVING_PAGE,
  payload: isLeavingPage,
})
