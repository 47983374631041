import React from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import { useDispatch } from "react-redux"
import useIsDarkTheme from "hooks/useIsDarkTheme"
import { setIsDarkTheme } from "state/actions"
import SunGradient from "images/sun-multi.svg"
import SunWhite from "images/sun-white.svg"
import MoonGradient from "images/moon-multi.svg"
import MoonWhite from "images/moon-white.svg"
import { th, sizes } from "styles"

const StyledModeToggleButton = styled(motion.div)`
  display: flex;
  width: 186px;
  box-shadow: 0px 3px 6px #00000029;
  color: ${th("textColor")};
  background-color: ${th("modeToggleButtonColor")};
  border-radius: 8px;
`

const LightMode = styled(motion.div)`
  ${({ isSelected }) =>
    isSelected
      ? css`
          flex: 1;
          border: 1px solid ${th("modeToggleButtonBorderColor")};
          border-right: none;
        `
      : css`
          cursor: pointer;
        `}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: ${({ isSelected, theme }) =>
    !isSelected
      ? `linear-gradient(${theme.color.yellow}, ${theme.color.pink})`
      : "transparent"};
  color: ${({ isSelected, theme }) => !isSelected && theme.backgroundColor};
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  transition: background 200ms;
`

const DarkMode = styled(motion.div)`
  ${({ isSelected }) =>
    isSelected
      ? css`
          flex: 1;
          border: 1px solid ${th("modeToggleButtonBorderColor")};
          border-left: none;
        `
      : css`
          cursor: pointer;
        `}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: ${({ isSelected, theme }) =>
    !isSelected &&
    `linear-gradient(${theme.color.green}, ${theme.color.blue})`};
  color: ${({ isSelected, theme }) => !isSelected && theme.color.white};
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
`

const IconContainer = styled(motion.span)`
  display: flex;
  align-items: center;
`

const Sun = styled.img`
  width: 24px;
  height: 24px;
  padding: 0 4px;
`

const Moon = styled.img`
  width: 20px;
  height: 20px;
  padding: 0 4px;
`

const TextContainer = styled(motion.span)`
  user-select: none;
  ${({ isSelected }) =>
    isSelected &&
    css`
      padding-right: 8px;
      padding-left: 4px;
    `}
`

const SmallToggle = styled.div`
  position: fixed;
  bottom: 12px;
  right: 12px;
  width: 45px;

  @media ${sizes.mediumDesktop} {
    display: none;
  }
`

const SmallLightMode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: ${({ isSelected, theme }) =>
    !isSelected
      ? `linear-gradient(${theme.color.yellow}, ${theme.color.pink})`
      : theme.modeToggleButtonColor};
  color: ${({ isSelected, theme }) => !isSelected && theme.backgroundColor};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  transition: background 200ms;
`

const SmallDarkMode = styled.div`
  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${th("modeToggleButtonBorderColor")};
      border-top: none;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: ${({ isSelected, theme }) =>
    !isSelected &&
    `linear-gradient(${theme.color.green}, ${theme.color.blue})`};
  color: ${({ isSelected, theme }) => !isSelected && theme.color.white};
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  transition: background 200ms;
`

const IconImage = styled.img`
  width: 100%;
  height: auto;
`

const ModeToggleButton = ({ variant = "large", className }) => {
  const dispatch = useDispatch()
  const isDarkTheme = useIsDarkTheme()

  if (variant === "small") {
    return (
      <SmallToggle>
        <SmallLightMode
          onClick={() => dispatch(setIsDarkTheme(false))}
          isSelected={!isDarkTheme}
        >
          <IconImage
            src={!isDarkTheme ? SunGradient : SunWhite}
            alt="lights on"
          />
        </SmallLightMode>
        <SmallDarkMode
          onClick={() => dispatch(setIsDarkTheme(true))}
          isSelected={isDarkTheme}
        >
          <IconImage
            src={isDarkTheme ? MoonGradient : MoonWhite}
            alt="lights off"
          />
        </SmallDarkMode>
      </SmallToggle>
    )
  }

  // large variant
  return (
    <StyledModeToggleButton className={className}>
      <LightMode
        layout
        initial={{ borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px" }}
        onClick={() => dispatch(setIsDarkTheme(false))}
        isSelected={!isDarkTheme}
      >
        <IconContainer layout>
          <Sun src={!isDarkTheme ? SunGradient : SunWhite} alt="lights on" />
        </IconContainer>
        <TextContainer layout isSelected={!isDarkTheme}>
          {!isDarkTheme && " Light mode"}
        </TextContainer>
      </LightMode>
      <DarkMode
        layout
        initial={{
          borderTopRightRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
        onClick={() => dispatch(setIsDarkTheme(true))}
        isSelected={isDarkTheme}
      >
        <IconContainer layout>
          <Moon src={isDarkTheme ? MoonGradient : MoonWhite} alt="lights off" />
        </IconContainer>
        <TextContainer layout isSelected={isDarkTheme}>
          {isDarkTheme && "  Dark mode"}
        </TextContainer>
      </DarkMode>
    </StyledModeToggleButton>
  )
}

export default ModeToggleButton
