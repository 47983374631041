import { SET_IS_DARK_THEME, SET_IS_LEAVING_PAGE } from "./types"

export const isDarkTheme = (state = null, { type, payload }) => {
  switch (type) {
    case SET_IS_DARK_THEME:
      return payload
    default:
      return state
  }
}

export const isLeavingPage = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_LEAVING_PAGE:
      return payload
    default:
      return state
  }
}
