import { useSelector } from "react-redux"
import useSystemTheme from "react-use-system-theme"
import { isNil } from "ramda"

const useIsDarkTheme = () => {
  const isDarkTheme = useSelector(state => state.isDarkTheme)
  const isSystemDarkTheme = useSystemTheme() !== "light"

  return isNil(isDarkTheme) ? isSystemDarkTheme : isDarkTheme
}

export default useIsDarkTheme
