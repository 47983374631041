import React, { StrictMode } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
import favicon from "images/favicon.ico"
import { ThemeProvider } from "styled-components"
import Navbar from "components/Navbar"
import ModeToggleButton from "components/ModeToggleButton"
import useIsDarkTheme from "hooks/useIsDarkTheme"
import { lightTheme, GlobalStyle, darkTheme, sizes } from "styles"

const DesktopModeToggle = styled.div`
  display: none;

  @media ${sizes.mediumDesktop} {
    display: block;
    position: fixed;
    bottom: 40px;
    right: 40px;
  }
`

const PageWrapper = ({ children }) => {
  const isDarkTheme = useIsDarkTheme()

  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* do ternary below this way to avoid flicker of dark before light */}
      <ThemeProvider theme={!isDarkTheme ? lightTheme : darkTheme}>
        <StrictMode>
          <GlobalStyle />
          <Navbar />
          {children}
          <DesktopModeToggle>
            <ModeToggleButton />
          </DesktopModeToggle>
        </StrictMode>
      </ThemeProvider>
    </>
  )
}

export default PageWrapper
