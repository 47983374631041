import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import useIsDarkTheme from "hooks/useIsDarkTheme"
import ClientOnly from "components/ClientOnly"
import ModeToggleButton from "components/ModeToggleButton"
import LogoDark from "images/logo-dark.svg"
import LogoLight from "images/logo-light.svg"
import { th, sizes } from "styles"

const StyledNavbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media ${sizes.smallDesktop} {
    padding-top: 40px;
  }
`

const Logo = styled.img`
  height: 55px;

  @media ${sizes.mobileMedium} {
    height: 60px;
  }

  @media ${sizes.tabletPortrait} {
    height: 80px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  opacity: 0;
  pointer-events: none;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      pointer-events: initial;
    `}

  transition: opacity 200ms;
  background-color: ${th("overlayColor")};
  z-index: 6001;
`

const MenuItems = styled.div`
  margin-top: 40px;

  > * {
    margin-bottom: 20px;
  }

  > a {
    color: ${th("textColor")};
    font-size: 24px;
  }
`

const StyledModeToggleButton = styled(ModeToggleButton)`
  > :first-child {
    background-color: ${th("color.white")};
  }
`

const Hamburger = styled.div`
  position: absolute;
  top: 52px;
  right: 16px;
  width: 28px;
  height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 6002;

  @media ${sizes.mobileMedium} {
    right: 20px;
  }

  @media ${sizes.tabletPortrait} {
    right: 40px;
    top: 72px;
  }

  @media ${sizes.tabletLandscape} {
    display: none;
  }

  > * {
    position: absolute;
    left: 0;
    width: 26px;
    height: 2px;
    border-radius: 5px;
    border-color: ${th("textColor")};
    border: 1px solid;
    transition: 200ms;
    transform: rotate(0deg);
  }

  > :first-child {
    top: 0;
  }

  > :nth-child(2) {
    top: 8px;
  }

  > :last-child {
    top: 16px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      > :first-child {
        transform: rotate(45deg);
        top: 8px;
      }

      > :nth-child(2) {
        width: 0;
        opacity: 0;
      }

      > :last-child {
        transform: rotate(-45deg);
        top: 8px;
      }
    `};
`

const Navbar = () => {
  const isDarkTheme = useIsDarkTheme()
  const [isOpen, setIsOpen] = useState(false)
  return (
    <ClientOnly>
      <StyledNavbar>
        <Link to="/">
          {isDarkTheme ? <Logo src={LogoLight} /> : <Logo src={LogoDark} />}
        </Link>
        <Overlay isVisible={isOpen}>
          {isDarkTheme ? <Logo src={LogoLight} /> : <Logo src={LogoDark} />}
          <MenuItems>
            <StyledModeToggleButton />
            <a
              href="https://plumia.country"
              target="_blank"
              rel="noopener noreferrer"
            >
              Plumia website
            </a>
          </MenuItems>
        </Overlay>
        <Hamburger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <div />
          <div />
          <div />
        </Hamburger>
      </StyledNavbar>
    </ClientOnly>
  )
}

export default Navbar
